<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="正常" name="first">
        <el-table :data="sightCompanies" style="width: 100%" size="small">
          <el-table-column v-for="(value,key) in listLabel" :key="key" :prop="key" :label="value"></el-table-column>
          <el-table-column label="查看">
            <template slot-scope="scope">
              <el-button @click="goSightCompanyDetail(scope.row.id)" type="text" size="small">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-pagination
        :page-size="20"
        :pager-count="9"
        :current-page.sync="page"
        layout="total, prev, pager, next"
        :total="total"
        hide-on-single-page
        @current-change="handleCurrentChange"
      ></el-pagination>
      <el-tab-pane label="其他" name="second">
        <el-table :data="sightCompanyRest" style="width: 100%" size="small">
          <el-table-column v-for="(value,key) in listLabel" :key="key" :prop="key" :label="value"></el-table-column>
          <el-table-column label="查看">
            <template slot-scope="scope">
              <el-button @click="goSightCompanyDetail(scope.row.id)" type="text" size="small">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { AdminGetSightCompanyList } from "@/api/api";

export default {
  data() {
    return {
      activeName: "first",
      page: 1,
      total: 0,
      sightCompanies: [],
      sightCompanyRest: [],
      listLabel: {
        id: "编号",
        noteNameBiz: "简称",
        name:'全称',
        stateName: "状态",
        city: "城市",
        district: "区县",
        regphoneUser: "电话",
        contactPerson: "联系人",
        sightName: "景区"
      }
    };
  },
  mounted() {
    this.getSightCompanyListFn();
  },
  methods: {
    getSightCompanyListFn() {
      const that = this;
      AdminGetSightCompanyList({
        page: that.page
      }).then(res => {
        if (res.result) {
          that.sightCompanies = res.data.sightCompanies;
          that.sightCompanyRest = res.data.sightCompanyRest;
          that.total = res.data.total;
        }
      });
    },
    goSightCompanyDetail(id) {
      this.$router.push({
        name: "sightcompanydetail",
        query: { id: JSON.stringify(id) }
      });
    },

    handleCurrentChange(val) {
      this.page = val;
      this.getSightCompanyListFn();
    }
  }
};
</script>

<style>
</style>